export const useBreakpoint = () => {
  const screenWidth = ref<number>(0);
  const screenHeight = ref<number>(0);

  const onResize = () => {
    screenWidth.value = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    screenHeight.value = document.documentElement.clientHeight;
  };

  onMounted(() => {
    onResize();
    window.addEventListener('resize', onResize, true);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize, true);
  });

  const breakpointComputed = computed(() => {
    if (!screenWidth.value || screenWidth.value < 640) return 'xs';
    if (screenWidth.value >= 640 && screenWidth.value < 768) return 'sm';
    if (screenWidth.value >= 768 && screenWidth.value < 1024) return 'md';
    if (screenWidth.value >= 1024 && screenWidth.value < 1280) return 'lg';
    if (screenWidth.value >= 1280 && screenWidth.value < 1440) return 'xl';
    if (screenWidth.value >= 1440) return '2xl';
    return 'xl';
  });

  const xl2Up = computed(() => breakpointComputed.value === '2xl');
  const xlUp = computed(() => ['xl', '2xl'].includes(breakpointComputed.value));
  const lgUp = computed(() =>
    ['lg', 'xl', '2xl'].includes(breakpointComputed.value),
  );
  const mdUp = computed(() =>
    ['md', 'lg', 'xl', '2xl'].includes(breakpointComputed.value),
  );
  const smUp = computed(() =>
    ['sm', 'md', 'lg', 'xl', '2xl'].includes(breakpointComputed.value),
  );

  return {
    screen: { screenHeight, screenWidth },
    xl2Up,
    smUp,
    mdUp,
    lgUp,
    xlUp,
  };
};
